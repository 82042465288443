import { reactive, ref, defineComponent } from "vue";
import { Checkout } from "./../../classes/checkout";
export default defineComponent({
    props: {
        loadingUrl: String,
        signedParamsString: String
    },
    setup(props) {
        let initialized = false;
        const checkout = new Checkout(props.loadingUrl, props.signedParamsString);
        checkout.init();
        const items = ref([
            { message: 'Foo' },
            { message: 'Bar' }
        ]);
        function decreaseItem(item) {
        }
        function increaseItem(item) {
        }
        function removeItem(item) {
            items.value = items.value.filter((x) => x !== item);
        }
        return {
            initialized,
            decreaseItem,
            increaseItem,
            removeItem,
            items
        };
    },
    mounted() {
    }
});
