import axios from 'axios';
class Checkout {
    constructor(loadingUrl, signedParamsString) {
        this.loadingUrl = loadingUrl;
        this.signedParamsString = signedParamsString;
    }
    init() {
        axios.post(this.loadingUrl, {
            'sessid': '4854623dfb17f2e3c862f0541422ef5e',
            'soa-action': 'saveOrderAjax',
            'signedParamsString': this.signedParamsString
        });
    }
}
export { Checkout };
