import { createApp } from 'vue';
import CheckoutVueComponent from './../../vue/components/checkout/index.vue';
class CheckoutComponent {
    constructor() {
    }
    run() {
        this.initCheckoutComponent();
    }
    initCheckoutComponent() {
        const mountEl = document.querySelector("#app-checkout");
        const app = createApp(CheckoutVueComponent, Object.assign({}, mountEl.dataset));
        app.mount('#app-checkout');
    }
}
export { CheckoutComponent };
