import 'core-js/stable/dom-collections/for-each';
import 'waypoints/lib/noframework.waypoints.js';
import { SwiperComponent } from './swiper';
import { BootstrapTabComponent } from './bootstrap-tab';
import { ScrollToComponent } from './scroll-to';
import { CheckoutComponent } from './checkout';
import { inOutSine } from 'ease-component';
import './../scss/_variables';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './../scss/main';
class Application {
    constructor() {
    }
    run() {
        this.initWaypoints();
        this.initScrollTo();
        this.initSlider();
        this.initTab();
        this.initCheckout();
    }
    initWaypoints() {
        const scrollBtn = document.querySelector('.j-scroll-btn');
        const offsetElement = document.querySelector('.products-sliders-content');
        if (!scrollBtn || !offsetElement) {
            return;
        }
        new Waypoint({
            element: offsetElement,
            offset: () => {
                return -offsetElement.offsetHeight;
            },
            handler: (direction) => {
                if (direction == 'down') {
                    scrollBtn.classList.add('d-block');
                }
                else {
                    scrollBtn.classList.remove('d-block');
                }
            },
        });
    }
    initScrollTo() {
        const items = document.querySelectorAll('.j-scroll-btn');
        items.forEach(item => {
            let element = item;
            if (element.dataset.scrollHref) {
                let scrollTarget = document.querySelector(element.dataset.scrollHref);
                if (scrollTarget !== null) {
                    new ScrollToComponent({
                        triggerElement: item,
                        destination: scrollTarget,
                        ease: inOutSine,
                        scrollDuration: element.dataset.scrollDuration ? parseInt(element.dataset.scrollDuration) : 1000
                    }).run();
                }
            }
        });
    }
    initSlider() {
        const sliders = document.querySelectorAll('.j-slider-component');
        sliders.forEach(slider => {
            new SwiperComponent({
                element: slider,
                options: {}
            }).run();
        });
    }
    initTab() {
        const tabs = document.querySelectorAll('.j-tab-component');
        tabs.forEach(tab => {
            new BootstrapTabComponent({
                element: tab
            }).run();
        });
    }
    initCheckout() {
        const checkoutComponent = new CheckoutComponent();
        checkoutComponent.run();
    }
}
document.addEventListener('DOMContentLoaded', function () {
    new Application().run();
});
